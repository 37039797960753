<template>
  <div>
      <div class="box">
          <div class="backgoundUrl"></div>
          <div class="NoData">暂无数据！</div>
      </div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  created () {

  },
  mounted () {

  },
  methods: {

  }
}
</script>

<style scoped lang="less">
    .box {
        width: 120px;
        height: 120px;
        // border: 1px solid #d8d8d8;
        margin-left: 50%;
        transform: translateX(-50%);
        margin-top: 87px;
    }
    .backgoundUrl {
        width: 85px;
        height: 65px;
        background: url('~@/assets/noData.png') no-repeat;
        background-size: 85px;
        margin-left: 20px;
    } 
    .NoData {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #999999;
        line-height: 20px;
        margin-left: 30px;
    }
</style>
