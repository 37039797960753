<template>
  <div >
        <div class="btn">
            <el-button type="primary" size="mini" @click="Excelexport">Excel导出</el-button>
        </div>
        <el-row :gutter='10'  class="title">
<!--                <el-col  :xl="5" :lg="5" :md="6" style="padding-left:30px;">交易号</el-col>-->
<!--                <el-col  :xl="5" :lg="5" :md="6">订单编号</el-col>-->
<!--                <el-col  :xl="3" :lg="3" :md="3">交易账号</el-col>-->
                <el-col  :xl="4" :lg="4" :md="4" style="text-align: center">用途</el-col>
                <el-col  :xl="4" :lg="4" :md="4"  style="text-align: center">变更金额（元）</el-col>
                <el-col  :xl="3" :lg="3" :md="3"  style="text-align: center">账户金额（元）</el-col>
                <el-col  :xl="5" :lg="5" :md="5"  style="text-align: center">创建时间</el-col>
                <el-col  :xl="8" :lg="8" :md="8" >备注信息</el-col>
        </el-row>
        <!-- 有数据 -->
        <div v-if="OrderNumberShow"
            class="OrderNumberList"
            v-loading="loading"
            element-loading-text="拼命加载中...."
            element-loading-spinner="el-icon-loading"
            element-loading-background="#fff"
        >
            <el-row :gutter='10'  class="orderInfo" v-for="item in WalletLogList" :key="item.id">
<!--                <el-col :xl="5" :lg="5" :md="6" style="padding-left:30px;">{{item.number}}</el-col>-->
<!--                <el-col :xl="5" :lg="5" :md="6">{{item.trade_number}}</el-col>-->
<!--                <el-col :xl="3" :lg="3" :md="3">{{item.user ? item.user.phone : '未查找到'}}</el-col>-->
                <el-col :xl="4" :lg="4" :md="4" style="text-align: center">{{item.type_word}}</el-col>
                <el-col :xl="4" :lg="4" :md="4"  style="text-align: center">{{item.amount}}</el-col>
                <el-col :xl="3" :lg="3" :md="3"  style="text-align: center">{{item.balance}}</el-col>
                <el-col :xl="5" :lg="5" :md="5"  style="text-align: center">{{item.created_at}}</el-col>
                <el-col :xl="8" :lg="8" :md="8">{{item.memo}}</el-col>
            </el-row>
        </div>
  </div>
</template>

<script>
export default {
    props:{
        // 有无数据状态
        OrderNumberShow:{
            type:Boolean,
            default:false
        },
        WalletLogList:{
            type: Array,
            default:()=>[]
        }
    },
  data () {
    return {
        loading:true
    }
  },
  created () {
//  this.openloading()
  },
  mounted () {
         // 关闭loading
             setTimeout(()=>{
            //      this.loading.close();
            this.loading = false
             },500)
  },
  methods: {
          // loading
      openloading() {
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "#fff",
        target: document.querySelector(".lodaing") // 指定区域
      });
    },
    Excelexport(){
        this.$emit('Excelexport')
    }
  }
}
</script>

<style scoped lang="less">
    .btn {
        margin-top: 23px;
        margin-bottom: 18px;
    }
    .title {
        // height: 50px;
        background: #F7F8FA;
        font-size: 15px;
        font-weight: 600;
        color: #333333;
        // line-height: 50px;
        line-height: 30px;
        padding: 10px 0 10px;
    }
    .orderInfo {
        // line-height: 50px;
        padding: 15px 0;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        border-bottom: 1px solid #F7F7F7;
        margin-top: 1px;
        &:hover {
            box-shadow: 0px 5px 12px 4px rgba(172, 172, 172, 0.16);
        }
    }
   
.el-col{
    border: 1px solid transparent;
}
</style>
