<template>
  <div class="main">
    <!-- 头部余额 -->
    <balance-top
        @recharge='rechargeShow=$event'
        :userInfo='userInfo'
        :user='user'
    />
    <!-- 我的账单 搜索 -->
    <account-search
        :WalletLogTypeList='WalletLogTypeList'
        @search='search'
        @reset='reset'
        ref="accountSearch"
    />
    <!-- 订单 -->
    <order-number
        :OrderNumberShow='OrderNumberShow'
        :WalletLogList='WalletLogList'
        @Excelexport="Excelexport"
    />
    <!-- 无数据 -->
    <no-data v-if="!OrderNumberShow"/>
    <!-- 分页 -->
    <div class="paging">
      <account-paging
          v-if="OrderNumberShow && pagingShow"
          :meta='meta'
          @currentPage='currentPage'/>
    </div>
    <!-- 充值 -->
    <recharge
        @Refresh='Refresh'
        :rechargeShow='rechargeShow'
        @close='rechargeShow=$event'
        :OfflineRechargeInfo='OfflineRechargeInfo'
        :moneyList='moneyList'
    />

  </div>
</template>

<script>
import FileSaver from 'file-saver'
import request from "@/utils/request";
import AccountPaging from './compoents/accountPaging.vue'
import AccountSearch from './compoents/accountSearch.vue'
import balanceTop from './compoents/balanceTop.vue'
import NoData from './compoents/NoData.vue'
import OrderNumber from './compoents/orderNumber.vue'
import Recharge from './compoents/recharge.vue'
import * as XLSX from 'xlsx';
import {
  getOfflineRechargeInfo,
  getWalletLogTypeList,
  getWalletBalance,
  getWalletLogList,
  getFixedRechargeMoneyList,
  walletLogExport
} from '@/api/costManagement';
import {timestamp, dateFormat} from '@/utils/timejs';

export default {
  components: {balanceTop, AccountSearch, OrderNumber, AccountPaging, Recharge, NoData},
  data() {
    return {
      rechargeShow: false, //充值弹窗状态
      pagingShow: false, //分页显示
      OrderNumberShow: true, // 有无数据状态
      OfflineRechargeInfo: {}, //线下汇款数组
      WalletLogTypeList: [],//用途下拉框
      userInfo: {},//个人详情
      page: 1, //当前页
      perPage: 10, //一页多少条
      meta: {}, //页码
      WalletLogList: [], //订单数据
      startTime: '',//开始时间
      endTime: '',//结束时间
      type: null, //用途
      account: null,//账号
      moneyList: [],// 固定充值金额
      user: {},
    }
  },
  created() {

  },
  mounted() {
    // 个人详情
    this.getWalletBalance()
    // 线下汇款
    this.getOfflineRechargeInfo()
    // 用途下拉框
    this.getWalletLogTypeList()
    // 数据列表
    this.getWalletLogList()
    // 固定金额
    this.getFixedRechargeMoneyList()
    //打开弹窗
    this.openPopup()
  },
  methods: {
    //打开弹窗
    openPopup() {
      if (!!Number(this.$route.query.flag)) {
        this.rechargeShow = true
      }
    },
    // 线下汇款
    async getOfflineRechargeInfo() {
      try {
        const {data} = await getOfflineRechargeInfo()
        this.OfflineRechargeInfo = data
      } catch (error) {
        this.$message.error(error)
      }
    },
    // 用途下拉框
    async getWalletLogTypeList() {
      try {
        const {data} = await getWalletLogTypeList()

        this.WalletLogTypeList = data
      } catch (error) {
        this.$message.error(error)
      }
    },
    // 个人详情
    async getWalletBalance() {
      try {
        const {data} = await getWalletBalance()
        this.userInfo = data
        // this.user = this.userInfo.user
      } catch (error) {
        this.$message.error({
          message: error,
          duration: 2000
        })
      }
    },
    // 订单数据
    async getWalletLogList() {

      try {
        const {
          data:
              {
                data,
                meta
              }
        } = await getWalletLogList({
          page: this.page,
          perPage: this.perPage,
          startTime: this.startTime || undefined,
          endTime: this.endTime || undefined,
          type: this.type,
          userCode: this.account
        })
        this.meta = meta
        this.WalletLogList = data
        if (this.WalletLogList.length === 0) {
          this.OrderNumberShow = false
        } else {
          this.OrderNumberShow = true
          this.pagingShow = true
        }
      } catch (error) {

        this.$message.error(error)
      }

    },
    // 分页
    currentPage(event) {

      this.page = event
      this.getWalletLogList()
    },
// 搜索
    search({time, type, account}) {
      // this.startTime = dateFormat(time[0])
      this.startTime = timestamp(time[0])
      this.endTime = timestamp(time[1])

      this.page = 1
      this.perPage = 10
      // this.endTime = dateFormat(time[1])
      this.type = type
      this.account = account
      this.getWalletLogList()
    },
    // 重置
    reset() {
      this.account = '' //账号
      this.startTime = '' //开始时间
      this.endTime = ''//结束时间
      this.type = null
      this.getWalletLogList()
    },
    //固定金额
    async getFixedRechargeMoneyList() {
      try {
        const {data} = await getFixedRechargeMoneyList()
        this.moneyList = data
      } catch (e) {
        this.$message.error(e)
      }
    },
// 刷新页面
    Refresh() {
      this.rechargeShow = false
      this.getWalletLogList()
    },
    //导出
    async Excelexport() {

      return walletLogExport({
        type: this.$refs.accountSearch.purpose || undefined,
        startTime: timestamp(this.$refs.accountSearch.time[0]) || undefined,
        endTime: timestamp(this.$refs.accountSearch.time[1]) || undefined,
      }).then((data) => {
        window.location.href= data.data.info
      }).catch(e => {
        this.$message.error(e)
      })
    },
    downloadFile(data) {

      if (!data) {
        return
      }
      let url = window.URL.createObjectURL(new Blob([data]))
      console.log(url)
      let link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download', '费用')
      document.body.appendChild(link)
      link.click()
      // const blobName = '费用'
      // FileSaver.saveAs(url, blobName)


    //   let array  = this.WalletLogList
    //   var keyMap = {
    //     "amount" : "变更金额（元）",
    //     "balance" : "账户金额（元）",
    //     "created_at":'创建时间',
    //     "memo":'备注信息',
    //     "type_word":'用途',
    //   };
    //
    //   for(let i = 0;i < array.length;i++){
    //     let obj = array[i];
    //     for(let key in obj){
    //       let newKey = keyMap[key];
    //       if(newKey){
    //         obj[newKey] = obj[key];
    //         delete obj[key];
    //       } else {
    //         delete obj[key];
    //       }
    //     }
    //   }
    //
    //   console.log(array)
    //   //
    // let wscols = [{wch:50},{wch:50},{wch:50},{wch:50},{wch:50}]
    //
    //   let workbook = XLSX.utils.book_new();
    //   //2. 生成一个工作表，
    //   //2.2 把json对象转成工作表
    //   let sheet2 = XLSX.utils.json_to_sheet(array);
    //   sheet2["!cols"] = wscols;
    //   //3.在工作簿中添加工作表
    //   // XLSX.utils.book_append_sheet(workbook, sheet1, 'sheetName1'); //工作簿名称
    //   XLSX.utils.book_append_sheet(workbook, sheet2, '费用'); //工作簿名称
    //   // XLSX.utils.sheet_add_json(sheet1,data2);//把已存在的sheet中数据替换成json数据
    //   //4.输出工作表,由文件名决定的输出格式
    //   XLSX.writeFile(workbook, '费用.xlsx'); // 保存的文件名
    }

  },
}
</script>

<style scoped lang="less">

.paging {
  margin-top: 10px;
  margin-bottom: 10px;
}

</style>

