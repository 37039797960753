<template>
    <div>
        <el-pagination
        background
        layout="prev, pager, next,total"
        :total="meta.total"
        prev-text='上一页'
        next-text='下一页'
        :page-size="parseInt(meta.per_page)"
        :current-page="parseInt(meta.current_page)"
        @current-change="handleCurrentChange"
        >
        </el-pagination>
    </div>
</template>

<script>
export default {
  props:{
    meta:{
      type: Object,
      default:()=>{}
    }
  },
  data () {
    return {

    }
  },
  created () {

  },
  mounted () {

  },
  methods: {
    handleCurrentChange(currentPage){
       this.meta.current_page = currentPage;
       this.$emit('currentPage',currentPage)
    }
  }
}
</script>

<style scoped lang="less">
// /deep/.el-pagination {
//     margin-left: 30%;
// }
/deep/.btn-prev ,
/deep/.btn-next{
    padding: 0 10px !important;
}
/deep/.el-pagination__total {
    font-size: 14px !important;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    margin-left: 5px;
}
/deep/.el-pagination  {
    text-align: center;
}
</style>
