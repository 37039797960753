<template>
  <div>
      <div class="myBill">我的账单</div>
      <el-form ref="form"  inline size='mini'>
        <el-row :gutter="30">
<!--          <el-col :xs="24" :sm="12" :md="12" :lg="5" :xl="5">-->
<!--            <el-form-item label="交易账号">-->
<!--              <el-select v-model="account" placeholder="请选择账号" clearable>-->
<!--                <el-option-->
<!--                    v-for="item in allSubAccount"-->
<!--                    :key="item.id"-->
<!--                    :label="item.phone"-->
<!--                    :value="item.code"-->
<!--                ></el-option>-->
<!--              </el-select>-->
<!--              &lt;!&ndash;              <el-input v-model="account" placeholder="请选择账号"></el-input>&ndash;&gt;-->
<!--            </el-form-item>-->
<!--          </el-col>-->
          <el-col :xs="24" :sm="12" :md="12" :lg="5" :xl="5">

              <el-form-item label="用途">
                <el-select v-model="purpose" placeholder="请选择用途类型" clearable>
                  <el-option v-for="(item , index) in WalletLogTypeList" :key="index" :label="item.name" :value="item.code "></el-option>
                </el-select>
              </el-form-item>
              <!--              <el-input v-model="account" placeholder="请选择账号"></el-input>-->

          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="9" :xl="6">
            <el-form-item label="创建时间" label-width="90px">
              <el-date-picker
                  v-model="time"
                  type="datetimerange"
                  :picker-options="pickerOptions"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  align="right">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4" :xl="5">
            <el-form-item>
              <el-button type="primary" size="mini" class="search" @click="search">搜索</el-button>
              <el-button  size="mini" plain @click="reset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
          <!-- <el-form-item label="部门" label-width="70px">
              <el-select v-model="department" placeholder="请选择一项">
                  <el-option label="产品" value="产品"></el-option>
                  <el-option label="总经办" value="总经办"></el-option>
                  <el-option label="设计" value="设计"></el-option>
              </el-select>
          </el-form-item> -->

  </div>
</template>

<script>
import {getASubAccount} from "@/api/login";
import {dateFormat,timestamp} from "@/utils/timejs";

export default {
    props:{
        WalletLogTypeList:{
            type: Array,
            default:()=>[]
        }
      
    },
  data () {
    return {
      aaa:'aaa',
        account:'' ,//账号
        purpose:'', //用途
        // department:'', //部门
        time:[], //创建时间
        pickerOptions:{
             disabledDate(time) {
            return time.getTime() > Date.now();
          }
        },
      allSubAccount:[]
    }
  },
  created () {

  },
  mounted () {
      // this.getASubAccount()
  },
  methods: {

    //获取子账号
    async getASubAccount(){
      try {
        const data = await getASubAccount()
        this.allSubAccount = data?.data
      }catch (e) {
        const {msg} = e
        this.$message.error(msg)
      }
    },
      //搜索
   search(){
     this.time === null ? this.time = [] : this.time
     this.$emit('search', {time:this.time,type: this.purpose, account: this.account})

   },
   //重置
   reset(){
        this.account=''
        this.purpose = ''
        this.time=[]
        this.$refs.form.resetFields()
        this.$emit('reset')
   
   }
  },
  computed: {

  },
  watch:{
  }
}
</script>

<style scoped lang="less">
    .myBill {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
        margin-bottom: 15px;
    }
    /deep/ .el-form {
        border-bottom: 1px solid #eeeeee;
        padding-bottom: 8px;
    }

    /deep/.el-form-item__content {
      flex: 1;
      width: 100%;
    }
    /deep/.el-input .el-input--mini {
      width: 100%;
    }
    /deep/.el-select--mini {
      width: 100%;
    }
    /deep/.el-input__inner {
      width: 100% !important;
    }
    /deep/.el-form-item {
      display: flex;
      width: 100%;
    }
    /deep/.el-form-item__label {
      width: auto !important;
    }
</style>
