<template>
  <div>
        <!-- <div class="loading"> -->
            <div><i class="el-icon-loading"></i><div class="text">正在打开<span class="color">支付宝</span> ，请耐心等待...</div></div>
            <!-- <div class="btn"><el-button type="primary" size="small" round @click="clickBTn">授权完成</el-button></div> -->
        <!-- </div> -->
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  created () {

  },
  mounted () {

  },
  methods: {
    clickBTn(){
        this.$emit('loadingShow')
    }
  }
}
</script>

<style scoped lang="less">
    // .loading {
    //     position: absolute;
    //     top: 120px;
    //     left: 165px;
    //     width: 400px;
    //     height: 400px;
    //     background: #FFFFFF;
    //     z-index: 99999;
    //     box-shadow: 0px 12px 48px 16px rgba(0, 0, 0, 0.08);
    //     border-radius: 10px;
    //      }
        .el-icon-loading {
            font-size: 50px;
            color: #FA4A14;
            position: absolute;
            left: 50%;
            top: 40%;
           margin-left: -25px;
           margin-top: -25px;
           font-weight: 600;
        }
        .text {
            position: absolute;
            left: 50%;
            top: 55%;
            transform: translateX(-50%);
            font-size: 16px;
            font-weight: 600;
            color: #333333;
            line-height: 22px;
        }
        // .btn {
        //   margin-top: 314px;
        //   margin-left: 165px;
        // }
      .color {
        color: #FA4A14;
      }
</style>
