import request from "@/utils/request";

const prefix = '/wallet'

//获取账号信息
// export const getOfflineRechargeInfo = (
//     callback
//   ) => {
//
//       return request(`${prefix}/getOfflineRechargeInfo`, { method: 'get'}, callback)
//   }
export const getOfflineRechargeInfo = (
    callback
) => {

    return request('/wallet/offlineRemittance', {method: 'get'}, callback)
}

// 用途下拉框
// export const getWalletLogTypeList = (
//   callback
// ) => {
//
//     return request(`${prefix}/getWalletLogTypeList`, { method: 'get'}, callback)
// }
export const getWalletLogTypeList = (
    callback
) => {

    return request('/wallet/logTypeList', {method: 'get'}, callback)
}

// 个人详情
//  export const getWalletBalance = (
//   callback
// ) => {
//
//     return request(`${prefix}/getWalletBalance`, { method: 'get'}, callback)
// }
export const getWalletBalance = (
    callback
) => {

    return request('/wallet/balance', {method: 'get'}, callback)
}


// 交易数据
// export const getWalletLogList = (
//   params,
//   callback
// ) => {
//
//     return request(`${prefix}/getWalletLogList`, {params, method: 'get'}, callback)
// }

export const getWalletLogList = (
    params,
    callback
) => {

    return request('/wallet/list', {params, method: 'get'}, callback)
}


//规定金额
// export const getFixedRechargeMoneyList = (
//
//   callback
// ) => {
//
//     return request(`${prefix}/getFixedRechargeMoneyList`, { method: 'get'}, callback)
// }

export const getFixedRechargeMoneyList = (
    callback
) => {

    return request('/wallet/rechargeAmountList', {method: 'get'}, callback)
}

// 二维码
export const onlineRechargeByAlipay = (
    params,
    callback
) => {

    return request(`${prefix}/onlineRechargeByAlipay`, {params, method: 'post'}, callback)
}
// 充值
// export const onlineRechargeByAlipayWeb = (
//   params,
//   callback
// ) => {
//
//     return request(`${prefix}/onlineRechargeByAlipayWeb`, {params, method: 'post'}, callback)
// }
export const onlineRechargeByAlipayWeb = (
    params,
    callback
) => {

    return request('/wallet/recharge', {params, method: 'post'}, callback)
}

// 导出用户费用excel
// export const walletLogExport = (
//     params,
//     callback
// ) => {
//
//     return request(`${prefix}/walletLogExport`, {params, method: 'get'}, callback)
// }
export const walletLogExport = (
    params,
    callback
) => {

    return request('/wallet/exportUrl', {params, method: 'get'}, callback)
}