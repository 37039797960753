<template>
  <div>
    <div class="nav">
      <div class="userInfo">
        <div>
            <div class="phone">商户：{{ userInfo.merchantName}}</div>
<!--            <div>免费版<span class="time">（到期时间：永久有效）</span></div>-->
        </div>
        <!-- <div>
            <el-button type="primary" size="mini">升级会员</el-button>
        </div> -->
      </div>
      <div class="border"></div>
      <div class="account">
        <div>
          <div class="money">{{userInfo.balance}}</div>
          <div class="balance">总账号余额（元）</div>
        </div>
        <div>
          <el-button type="primary" size="mini" @click="rechargeShow">充值</el-button>
        </div>
      </div>
    
    </div>
    <div class="background"></div>
  </div>
</template>

<script>
export default {
  props:{
    userInfo:{
      type: Object,
      default: ()=>{}
    },
    user:{
      type: Object,
      default: ()=>{}
    }
   
  },
  data () {
    return {

    }
  },
  created () {

  },
  mounted () {

  },
  methods: {
    // 充值
    rechargeShow(){
      this.$emit('recharge',true)


    }
  }
}
</script>

<style scoped lang="less">
.nav {
  display: flex;
  height: 48px;
  background: #FFFFFF;
  margin-bottom: 20px;

}
.userInfo {
  width: 510px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  align-items: center;
  margin-right: 50px;
  .phone {
    font-size: 16px;
    font-weight: 600;
    color: #333333;
    margin-bottom: 6px;
  }
  .time {
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    line-height: 17px;
  }
}
.border {
  width: 1px;
  height: 55px;
  background: #eeeeee;
}
.account {
  display: flex;
  width: 330px;
  justify-content: space-between;
  margin-left: 50px;
  align-items: center;
  .money {
    font-size: 21px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FA4A14;
    line-height: 29px;
    margin-bottom: 4px;
  }
  .balance {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
  }
}
.background {
  height: 1px;
  background: #D8D8D8;
  margin-bottom: 20px;
}

</style>