<template>
  <div>

    <el-dialog
        title="账户充值"
        :visible.sync="rechargeShow"
        :before-close="handleClose"
    >
      <div v-if="accountLodingShow">
        <div class="border"></div>
        <div class="hint"><span class="el-icon-warning-outline"></span><span>建议使用线上充值实时到账，线下汇款需3-7个工作日到账</span></div>
        <el-tabs v-model="activeName">
          <el-tab-pane label="线上充值" name="first">
            <div class="flex">
              <div class="money_text">充值金额</div>
              <div class="money" v-for="(item,index) in moneyList" :key="index" @click='fixedMoney(item)'
                   :class="{orange:active===item}">{{ item }}
              </div>
              <div>
                <el-form ref="form" :model="form" :rules='rules' inline>
                  <el-form-item prop="money">
                    <el-input v-model.trim="form.money" size="mini" @focus='focus' @blur='blur' @submit.native.prevent></el-input>
                    <span class="money_yuan">元</span>
                  </el-form-item>

                </el-form>

              </div>
            </div>
            <div class="payment">
              <span>付款方式</span>
              <span class="Alipay"></span>
              <span><span class="iconfont icon-zhifubao"></span> 支付宝</span>
            </div>
            <div class="moneypayment_flex" v-if="RechargeShow">
              <div class="moneypayment">
                应付金额：<span class="moneypayment_num">¥{{ paymentMoney }}</span>
              </div>
              <el-button type="primary" style="width:90px; margin-right:20px ;" @click="Recharge">充值</el-button>
            </div>
            <div class="moneypayment_flex" v-else>
              <div>
                <div class="textHint">请在新打开的页面进行充值</div>
                <div class="Recharge_completed">充值完成后，根据您的情况点击下面按钮</div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="银行转帐（线下汇款）" name="second">
            <div class="info">我司信息</div>
            <div class="corporationInfo">
              <div>
                <span>公司名称：</span><span class="color">{{ OfflineRechargeInfo.company_name }}</span>
              </div>
              <div>
                <span>银行名称：</span><span class="color">{{ OfflineRechargeInfo.bank_name }}</span>
              </div>
              <div>
                <span>开户银行：</span><span class="color">{{ OfflineRechargeInfo.bank_name_detail }}</span>
              </div>
              <div>
                <span>银行账号：</span><span class="color">{{ OfflineRechargeInfo.bank_account }}</span>
              </div>
            </div>
            <div class="hintInfo">温馨提示：为确保您的资金及时到账，建议您打款前与财务联系。客服电话：{{ OfflineRechargeInfo.service_phone }}</div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <account-loading v-else/>
    </el-dialog>
    <div class="divform"></div>


  </div>
</template>

<script>
import {onlineRechargeByAlipayWeb} from '@/api/costManagement';
import '@/assets/styles/icon.less'
import accountLoading from './accountLoading.vue';

export default {
  components: {accountLoading},
  props: {
    rechargeShow: {
      type: Boolean,
      default: false
    },
    OfflineRechargeInfo: {
      type: Object,
      default: () => {
      }
    },
    moneyList: {
      type: Array,
      default: () => []
    },
    url: {
      type: String,
      default: ''
    }
  },
  data() {
    let validatePass = (rule, value, callback) => {

      if (Number(value) > 999999) {
        callback(new Error('因金额太大，请联系客服，银行转账'))
      } else if (Number(value) < 0.01 && Number(value) > 0) {
        callback(new Error('金额必须大于等于0.01'))
      } else {
        callback()
      }
    }
    return {
      accountLodingShow: true,
      activeName: 'first',
      form: {
        money: '1000.00', // 文本框的金额
      },
      active: '1000.00', // 选中的金额
      paymentMoney: null, // 应付金额
      RechargeShow: true, // 充值状态
      info: null,
      rules: {
        money: [
          {pattern: /^\d+(\.\d+)?$/, message: '请输入数字'},
          {validator: validatePass, trigger: 'blur'}
        ]
      }
    }
  },
  created() {

  },
  mounted() {
    this.paymentMoney = this.active

  },
  methods: {
    //   关闭弹窗
    handleClose() {
      this.$emit('close', false)
      this.RechargeShow = true
      this.accountLodingShow = true
      this.form.money = '1000.00'
      this.paymentMoney = '1000'

    },
    //   点击
    fixedMoney(item) {
      this.active = item


    },
    // // 聚焦
    focus() {
      this.active = this.form.money

    },
    //失去焦点
    blur() {
      this.paymentMoney = this.form.money
    },

    // 充值
    Recharge() {
      let rule = /^\d+(\.\d+)?$/
      if (!this.paymentMoney) {
        return this.$message.error('请输入金额')
      } else if (!rule.test(this.paymentMoney)) {
        return this.$message.error('请输入数字')
      } else if (Number(this.paymentMoney) < 0.01) {
        return this.$message.error('金额必须大于等于0.01')
      } else if (Number(this.paymentMoney) > 999999) {
        this.activeName = 'second'
        return this.$message.error('因金额太大，请联系客服，银行转账')
      } else {
        this.onlineRechargeByAlipayWeb()
        this.accountLodingShow = false
      }


    },
    // 获取表单信息
    async onlineRechargeByAlipayWeb() {
      try {
        const {
          data : {
          info
        }} = await onlineRechargeByAlipayWeb(
            {
              amount: this.paymentMoney,
              channel:'alipayweb'
            }
        )
        this.info = info
        // 当前页
        let divForm = document.getElementsByTagName('divform')
        if (divForm.length) {
          document.body.removeChild(divForm[0])
        }
        const div = document.createElement('divform')
        div.innerHTML = this.info
        document.body.appendChild(div)
        document.getElementById('alipay_submit').submit()
      } catch (error) {
        this.$message.error(error)
      }
    }

  },
  watch: {
    active() {
      this.paymentMoney = this.active
    },
  }
}
</script>

<style scoped lang="less">
/deep/ .el-dialog {
  width: 600px;
  height: 428px;
  background: #FFFFFF;
  box-shadow: 0 12px 48px 16px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
}

/deep/ .el-dialog__title {
  font-size: 18px;
  font-weight: 600;
  color: #333333;
  line-height: 25px;
}

/deep/ .el-dialog__body {
  padding: 0 20px;
}

.hint {
  position: relative;
  width: 560px;
  height: 30px;
  background: rgba(250, 74, 20, 0.1);
  line-height: 30px;
  padding-left: 117px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  margin-bottom: 20px;

  .el-icon-warning-outline {
    position: absolute;
    font-size: 20px;
    left: 85px;
    top: 6px;
    color: #FA4A14;
  }
}

.border {
  width: 560px;
  height: 1px;
  background: #D8D8D8;
  margin-bottom: 5px;
  margin-top: 10px;
}

.flex {
  display: flex;
  align-items: center;
  margin-top: 8px;

  .money_text {
    margin-right: 10px;
  }

  .money {
    background: #FFFFFF;
    border-radius: 2px;
    border: 1px solid #D3D3D3;
    padding: 0 15px;
    line-height: 26px;
    margin-right: 8px;
    cursor: pointer;
  }
}

.info {
  margin-top: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
  margin-bottom: 10px;
}

.corporationInfo {
  width: 560px;
  height: 150px;
  padding: 20px;
  background: #F7F8FA;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 30px;

  .color {
    color: #333333;
  }
}

.hintInfo {
  font-size: 12px;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
  margin-top: 10px;
}

/deep/ .el-input {
  width: 178px;
  background: #FFFFFF;
  border-radius: 2px;
}

.orange {
  color: #FA4A14;
  border: 1px solid #FA4A14 !important;
}

/deep/ .el-input__inner {
  font-size: 14px;
  border-radius: 2px;
}

.payment {
  margin-top: 20px;
  padding-bottom: 70px;
  border-bottom: 1px solid #EEEEEE;
}

.Alipay {
  margin-left: 10px;
  margin-right: 4px;
}

.moneypayment {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 20px;

  .moneypayment_num {
    color: #FA4A14;
  }
}

.moneypayment_flex {
  display: flex;
  margin-top: 40px;
  justify-content: space-between;
}

.textHint {
  font-size: 14px;
  font-weight: 500;
  color: #333333;
  line-height: 20px;
}

.Recharge_completed {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}

.icon-zhifubao {
  font-size: 18px;
}

/deep/ .el-form,
/deep/ .el-form-item__content,
/deep/ .el-input {
  height: 35px;
  margin-bottom: 3px;
}

.money_yuan {
  margin-left: 5px;
}
</style>
